import { animated, useSprings } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { clamp } from '@kaliber/math'
import { useHistory } from '@kaliber/routing'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useReportError } from '/machinery/ReportError'
import { useDerivedSignals, useDerivedSignal, useOnSignalChange, useSignal, useSignalValue } from '/machinery/signals'
import { IsActiveContext } from '/machinery/IsActive'
import { useEvent } from '/machinery/useEvent'
import { useWindowHeight } from '/machinery/useWindowHeight'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { useArticlesWithReadState } from '/domain/hooks/useArticlesWithReadState'

import { DrawerArticleLinkButton } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { MenuDeckFeed } from '/features/pageOnly/Menu'
import { CardHero } from '/features/regionArticles/cards/CardHero'
import { CardDefault } from '/features/regionArticles/cards/CardDefault'
import { CardUitgelezen } from '/features/regionArticles/cards/CardUitgelezen'
import { CardGedicht } from '/features/regionArticles/cards/CardGedicht'
import { CardSnackables } from '/features/regionArticles/cards/CardSnackables'
import { CardKunstcollectie } from '/features/regionArticles/cards/CardKunstcollectie'
import { CardBorder } from '/features/regionArticles/cards/CardBorder'
import { DeckToc } from '/features/regionArticles/DeckToc'
import { Logo } from '/features/regionArticles/Logo'
import { extractArticles } from '/features/regionArticles/extractArticles'
import { findCover } from '/features/regionArticles/findCover'
import { useDrawerGestures } from '/features/regionArticles/useDrawerGestures'
import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { CardMemberOffer } from '/features/regionArticles/cards/CardMemberOffer'
import { Search } from '/features/regionArticles/Search'

import mediaStyles from '/cssGlobal/media.css'
import styles from './DeckFeed.css'

export function DeckFeed({ items, region, issues }) {
  const { currentArticleIndex$, setCurrentArticleIndex, setActiveItem } = useActiveItemIndexSignalWithHistory({ items, determineSlug: item => item.metadata.rubric.slug })
  const { articles } = useArticlesWithReadState({ articles: extractArticles(items) })

  const renderDeckItemComponent = ({ item }, idx) => <DrawerArticleLinkButton key={idx} onClick={() => handleArticleClick(item)} {...{ item }} />

  const { bind, searchDrawer, tocDrawer } = useDrawerGestures({ dragGesturesEnabled: true })

  const drawerIsVisible = searchDrawer.isOpen || tocDrawer.isOpen

  const cover = findCover(items)

  const finishedReading$ = useDerivedSignal(
    currentArticleIndex$,
    currentArticleIndex => currentArticleIndex === articles.length + 1
  )

  return (
    <div className={styles.component}>
      <MenuDeckFeed
        articleCount={articles.length}
        layoutClassName={styles.menuLayout}
        {...{ tocDrawer, searchDrawer, currentArticleIndex$, finishedReading$ }}
      />

      <DeckToc
        onClose={tocDrawer.handleClose}
        isOpen={tocDrawer.isOpen}
        coverImage={cover.content.hero.image}
        layoutClassName={styles.tocLayout}
        {...{ region, articles, issues, renderDeckItemComponent }}
      />

      <Search
        onClose={searchDrawer.handleClose}
        isOpen={searchDrawer.isOpen}
        coverImage={cover.content.hero.image}
        layoutClassName={styles.searchLayout}
      />

      <div className={styles.deckContainer} {...bind()}>
        <Deck
          onCurrentArticleChange={setCurrentArticleIndex}
          enabled={!drawerIsVisible}
          layoutClassName={styles.deckLayout}
          {...{ items, currentArticleIndex$, region, issues }}
        />
      </div>

    </div>
  )

  function handleArticleClick(article) {
    const index = items.findIndex(x => x.id === article.id)

    setActiveItem(article)
    getMetaDataActiveCard(article, items, index)
    searchDrawer.handleClose()
    tocDrawer.handleClose()
  }
}

function Deck({
  items,
  issues,
  onCurrentArticleChange,
  currentArticleIndex$,
  enabled,
  region,
  layoutClassName = undefined
}) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const windowHeight = useWindowHeight()

  const minimalDistanceForIndexChange = Math.min(windowHeight / 2, 500)
  const minimalTimeBetweenTransitionsInMs = 500

  const { springs, onDrag, onDragEnd } = useCardSprings({
    length: items.length, activeIndex$: currentArticleIndex$, minimalDistanceForIndexChange
  })

  const bindGestures = useCardGestures({
    enabled,
    onNextSlide: handleNextSlide,
    onPreviousSlide: handlePreviousSlide,
    onDrag,
    onDragEnd,
    minimalDistanceForIndexChange,
    minimalTimeBetweenTransitionsInMs,
  })

  useCardKeyBindings({
    enabled,
    onNextSlide: handleNextSlide,
    onPreviousSlide: handlePreviousSlide,
  })

  const isActiveSignals = useDerivedSignals(currentArticleIndex$, index =>
    items.map((_, i) => i === index)
  )

  const isFirst$ = useDerivedSignal(currentArticleIndex$, index => index === 0)

  React.useEffect(
    () => {
      const activeIndex = currentArticleIndex$.get()
      const newItem = items[activeIndex]
      getMetaDataActiveCard(newItem, items, activeIndex)
    },
    []
  )

  return (
    <div className={cx(styles.componentDeck, layoutClassName)} {...bindGestures()}>
      <AnimatedLogo isBig$={isFirst$} layoutClassName={styles.deckLogoLayout} />

      {springs.map(({ y, scale, pointerEvents, touchAction, opacity, overlayOpacity }, index) => {
        const item = items[index]
        const isActive$ = isActiveSignals[index]

        return (
          <Card
            key={index}
            layoutClassName={styles.cardLayout}
            {...{ item, issues, isActive$, region }}
            animation={{
              '--overlay-opacity': overlayOpacity,
              x: isViewportLg ? '-50%' : '0',
              y,
              scale,
              zIndex: -index,
              pointerEvents,
              touchAction,
              opacity,
            }}
          />
        )
      })}
    </div>
  )

  function handleNextSlide() {
    const activeIndex = currentArticleIndex$.get()

    if (activeIndex < items.length - 1) {
      const newIndex = activeIndex + 1
      const newItem = items[newIndex]
      getMetaDataActiveCard(newItem, items, newIndex)
      onCurrentArticleChange(newIndex)
    }
  }

  function handlePreviousSlide() {
    const activeIndex = currentArticleIndex$.get()

    if (activeIndex > 0) {
      const newIndex = activeIndex - 1
      const newItem = items[newIndex]
      getMetaDataActiveCard(newItem, items, newIndex)
      onCurrentArticleChange(newIndex)
    }
  }
}

function Card({ isActive$, item, issues, animation, region, layoutClassName = undefined }) {
  return (
    <animated.div style={animation} className={cx(styles.componentCard, layoutClassName)}>
      <IsActiveContext {...{ isActive$ }}>
        <CardContent layoutClassName={styles.cardContentLayout} {...{ item, issues, isActive$, region }} />
      </IsActiveContext>
    </animated.div>
  )
}

function AnimatedLogo({ layoutClassName, isBig$, subtitle = undefined }) {
  const isBig = useSignalValue(isBig$)

  return <Logo hasGradient {...{ isBig, subtitle, layoutClassName }} />
}

function CardContent({ item, issues, layoutClassName, region }) {
  const reportError = useReportError()
  const animations = useCardAnimations()

  switch (item.metadata.template) {
    case 'default': return <CardDefault {...{ item, layoutClassName }} />
    case 'hero': return <CardHero {...{ item, region, layoutClassName }} />
    case 'research': return <CardBorder {...{ item, layoutClassName }} />
    case 'single_snackable': return <CardBorder {...{ item, layoutClassName }} />
    case 'single_snackable_content': return <CardBorder {...{ item, layoutClassName }} />
    case 'kaliber-snackables': return <CardSnackables {...{ item, layoutClassName }} />
    case 'kunstcollectie': return <CardKunstcollectie {...{ item, layoutClassName }} />
    case 'gedicht': return <CardGedicht {...{ item, layoutClassName }} />
    case 'kaliber-member_offer': return <CardMemberOffer {...{ item, layoutClassName }} />
    case 'kaliber-uitgelezen': return <CardUitgelezen {...{ item, issues, animations, layoutClassName }} />
    default: {
      reportError(new Error(`Unknown card item template '${item.template}'`))
      return null
    }
  }
}

function useCardGestures({
  enabled,
  onNextSlide,
  onPreviousSlide,
  onDrag,
  onDragEnd,
  minimalDistanceForIndexChange,
  minimalTimeBetweenTransitionsInMs,
}) {
  const bind = useGesture( // TODO: Erik - switch to 'createGesture' so we only use the drag and wheel gestures
    {
      onDrag({ down, swipe: [, swipeY], movement: [, my], cancel, canceled }) {
        if (!enabled || canceled) return // preferably `enabled` should modify the enabled property, but that causes useGesture to become bugged and stop calling this function

        if (down) onDrag({ movementY: my })
        else onDragEnd()

        if (canceled) return

        const draggedFarEnoughUp = my < -minimalDistanceForIndexChange
        const draggedFarEnoughDown = my > minimalDistanceForIndexChange
        const swipedUp = swipeY === -1
        const swipedDown = swipeY === 1

        if (draggedFarEnoughUp || swipedUp)
          cancelAndCall(onNextSlide)
        else if (draggedFarEnoughDown || swipedDown)
          cancelAndCall(onPreviousSlide)

        function cancelAndCall(callback) {
          cancel()
          callback()
        }
      },
      onWheel: ({ active, movement: [, my], velocity: [, vy], memo = { offsetY: 0, transitionTime: 0 } }) => {
        if (!enabled) return

        const { offsetY, transitionTime } = memo
        const actualMovement = my - offsetY

        const enoughTimeBetweenTransitions = (Date.now() - transitionTime) > minimalTimeBetweenTransitionsInMs

        const scrolledFarEnoughUp = actualMovement < -minimalDistanceForIndexChange
        const scrolledFarEnoughDown = actualMovement > minimalDistanceForIndexChange
        const scrolledFastEnoughUp = actualMovement < 0 && vy > 2.5
        const scrolledFastEnoughDown = actualMovement > 0 && vy > 2.5

        const shouldTransition = scrolledFarEnoughUp || scrolledFarEnoughDown || scrolledFastEnoughUp || scrolledFastEnoughDown
        const willTransition = enoughTimeBetweenTransitions && shouldTransition
        const canDrag = active && enoughTimeBetweenTransitions && !shouldTransition

        if (canDrag) onDrag({ movementY: (-actualMovement) / 2 })
        else onDragEnd()

        if (!willTransition) return { offsetY: enoughTimeBetweenTransitions ? offsetY : my, transitionTime }

        if (scrolledFarEnoughUp || scrolledFastEnoughUp)
          return transition(onPreviousSlide)
        else if (scrolledFarEnoughDown || scrolledFastEnoughDown)
          return transition(onNextSlide)

        function transition(callback) {
          callback()
          return { offsetY: my, transitionTime: Date.now() }
        }
      },
    }, { drag: { axis: 'y', filterTaps: true } }
  )

  return bind
}

function useCardSprings({ length, activeIndex$, minimalDistanceForIndexChange }) {
  const { springs: positionSprings, onDrag, onDragEnd } = useCardPositionSprings({
    length, activeIndex$, minimalDistanceForIndexChange
  })

  const { springs: isActiveSprings } = useCardIsActiveSprings({ length, activeIndex$ })

  return {
    springs: positionSprings.map((positionProps, i) => {
      const isActiveProps = isActiveSprings[i]
      return { ...positionProps, ...isActiveProps }
    }),
    onDrag,
    onDragEnd
  }
}

function useCardIsActiveSprings({ length, activeIndex$ }) {
  const [springs, api] = useSprings(length, determineProps)

  useOnSignalChange({ ifChanged: activeIndex$, callback: () => { api.set(determineProps) } })

  return { springs }

  function determineProps(index) {
    const visibleItemCount = 4
    const activeIndex = activeIndex$.get()
    const isActive = activeIndex === index
    const visible = index - activeIndex < visibleItemCount

    return {
      overlayOpacity: isBetweenRange({ index, from: activeIndex, to: activeIndex + visibleItemCount })
        ? (1 / visibleItemCount) * (index - activeIndex)
        : 0,
      pointerEvents: isActive ? 'auto' : 'none',
      touchAction: isActive ? 'auto' : 'none',
      opacity: visible ? 1 : 0,
    }
  }
}

function isBetweenRange({ index, from, to }) {
  return index > from && index < to
}

function useCardPositionSprings({ length, activeIndex$, minimalDistanceForIndexChange }) {
  const { activePosition, outOfViewPosition, predefinedPosition, positionsChanged } = useCardPositions()
  const [springs, api] = useSprings(length, i => determineCardPosition(i, activeIndex$.get()))

  useOnSignalChange({ ifChanged: activeIndex$, callback: positionCards })
  if (positionsChanged) positionCards()

  return {
    springs,
    onDrag({ movementY }) {
      positionDisplacedCards(movementY)
    },
    onDragEnd() {
      positionCards()
    },
  }

  function positionCards() {
    api.start(i => determineCardPosition(i, activeIndex$.get()))
  }

  function positionDisplacedCards(movement) {
    const movementFactor = movement / minimalDistanceForIndexChange
    const isMovingToNext = movement < 0
    const isMovingToPrevious = movement > 0
    const clampedMovement = clamp({ min: -minimalDistanceForIndexChange, max: minimalDistanceForIndexChange, input: movement })

    api.start(i => {
      const activeIndex = activeIndex$.get()
      const isPreviousCard = i === (activeIndex - 1)
      const isCurrentCard = i === activeIndex

      return (
        isMovingToPrevious && isPreviousCard ? displacedOutOfViewPosition() :
        isMovingToNext && isCurrentCard ? draggedPosition() :
        determineDisplacedCardPosition(i)
      )
    })

    function displacedOutOfViewPosition() {
      return { y: outOfViewPosition().y + clampedMovement, scale: 0.95 }
    }

    function draggedPosition() {
      return { y: clampedMovement, scale: 0.95 }
    }

    function determineDisplacedCardPosition(i) {
      const preciseIndex = clamp({ min: i - 1, max: i + 1, input: i + movementFactor })
      return determineCardPosition(preciseIndex, activeIndex$.get())
    }
  }

  function determineCardPosition(index, activeIndex) {
    return (
      index === activeIndex ? activePosition :
      index < activeIndex ? outOfViewPosition() :
      predefinedPosition(index, activeIndex)
    )
  }
}

function useCardPositions() {
  const windowHeight = useWindowHeight()
  const positionsChanged = useValuesChanged([windowHeight])

  const cardOffset = windowHeight / 15

  const activePosition = { y: 0, scale: 1 }

  function outOfViewPosition() {
    return { y: -windowHeight, scale: 0.95 }
  }

  function predefinedPosition(index, activeIndex) {
    return {
      y: -(cardOffset / 2) - (cardOffset * (index - activeIndex)),
      scale: 0.95 - (0.10 * (index - activeIndex))
    }
  }

  return { activePosition, outOfViewPosition, predefinedPosition, positionsChanged }
}

function useValuesChanged(values) {
  const previousValuesRef = React.useRef(values)
  const previous = previousValuesRef.current
  previousValuesRef.current = values

  return (
    previous.length !== values.length ||
    previous.some((x, i) => x !== values[i])
  )
}

function useCardKeyBindings({ enabled, onNextSlide, onPreviousSlide }) {
  const onNextSlideEvent = useEvent(onNextSlide)
  const onPreviousSlideEvent = useEvent(onPreviousSlide)

  React.useEffect(
    () => {
      if (!enabled) return

      window.addEventListener('keydown', handleKeyPress, { passive: true })

      return () => { window.removeEventListener('keydown', handleKeyPress) }

      function handleKeyPress(e) {
        if (e.repeat) return
        if (e.key === 'ArrowUp') onPreviousSlideEvent()
        else if (e.key === 'ArrowDown') onNextSlideEvent()
      }
    },
    [enabled, onNextSlideEvent, onPreviousSlideEvent]
  )
}

function useActiveItemIndexSignalWithHistory({ items, determineSlug: originalDetermineSlug }) {
  const history = useHistory()

  const determineSlug = useEvent(originalDetermineSlug)
  const getSlugFromLocation = useEvent(location => location.hash.slice(1))
  const safeSelect = useEvent(select)

  const [currentArticleIndex$, internalSetCurrentArticleIndex] = useSignal(determineInitialIndex)

  React.useEffect(
    () => history.listen(x => safeSelect(getSlugFromLocation(x.location))),
    [history, safeSelect, getSlugFromLocation]
  )

  return {
    currentArticleIndex$,
    setCurrentArticleIndex: useEvent(navigateByIndex),
    setActiveItem: useEvent(navigate),
  }

  function determineInitialIndex() {
    const index = findIndexBySlug(getSlugFromLocation(history.location))
    return index < 0 ? 0 : index
  }

  function findIndexBySlug(slug) {
    return items.findIndex(x => determineSlug(x) === slug)
  }

  function navigate(item) {
    history.navigate(`#${determineSlug(item)}`, { replace: true })
  }

  function navigateByIndex(index) {
    const item = items[index]
    if (item) navigate(item)
  }

  function select(slug) {
    const index = findIndexBySlug(slug)
    if (index > -1) internalSetCurrentArticleIndex(index)
  }
}

function getMetaDataActiveCard(item, items, newIndex) {
  pushToDataLayer({
    event: 'highlighted-slide',
    metadata: { 
      content: {
        type: item?.metadata?.template,
        title: item?.content?.hero?.title,  
        feedindex: [newIndex, items.length - 1].join('_'), 
      }
    }
  })
}
